.intro__title {
  margin-bottom: 0;
}
.spike-label {
  font-size: 1.4rem;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: normal;
  font-family: "Quattrocento Sans", sans-serif;
}
