.footer {
  padding: 2.5rem 0;
  text-align: center;
  background: #222;
  color: #fff;
  border-top: 0.05rem solid #e5e5e5;
  font-size: 1.6rem;
}

.footer > p.updated {
  color: #282828;
}

.footer p:last-child {
  margin-bottom: 0;
}

.footer__description {
  line-height: 1.5;
  text-align: center;
  padding: 0 1rem;
  margin: 2rem auto;
  max-width: 50rem;
  /* color: rgb(42, 42, 42); */
  background: #222;
  color: #fff;
}

.footer__interwebs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  max-width: 50rem;
  margin: 2rem auto;
  padding: 1rem;
}

.footer__interwebs a {
  border: none;
  margin-right: 0.5em;
}

.footer__links {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.footer__links li {
  margin: 0.25rem 0.5rem;
  list-style-type: none;
  font-size: 1.5rem;
  line-height: 1.5;
}

.footer__links li a {
  text-decoration: none;
  border-bottom: 1px solid var(--fi-blue);
  color: #fff;
}

.footer__location {
  width: 100%;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #fff;
  font-size: 1.4rem;
}

.footer__emoji {
  padding-left: 9px;
}
