/* Preloader
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 5.2rem 1rem;
}
.preloader__image {
  /* animation: logo-spin infinite 15s linear; */
  height: 10vmin;
}
@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.preloader__header {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 3rem;
  font-size: 3rem;
  font-weight: 300;
  font-family: var(--sans);
}

.preloader__header span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
.preloader__header span:nth-child(2) {
  animation-delay: 0.2s;
}
.preloader__header span:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.preloader-card {
  min-height: 80vh;
}
